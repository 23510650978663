import { baseRequests } from './baseRequests'
import axios from 'axios'
import { apiUrl } from '@/config/environment'

class authRequests {
  login(username, password) {
    return new axios.create({
      baseURL: apiUrl,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    }).post('Authenticate/Login', { UserName: username, Password: password })
  }

  validateToken() {
    return baseRequests().get('Authenticate/ValidateToken')
  }
}

export default new authRequests()
