<template>
  <div class="flex items-center">
    <label class="inline-block relative h-[16px] w-[30px] sm:h-[22px] sm:w-[40px]" :for="id">
      <input :tabindex="tabIndex" type="checkbox" class="btn-toggle w-0 h-0" :id="id" :title="title" :value="modelValue"
        :checked="modelValue" @change="$emit('GdChange', $event.target.value)"
        @keypress.enter="$emit('GdChange', $event.target.value)" :disabled="disable" />
      <div class="slider round"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'GdToggle',
  props: {
    id: {
      type: String,
      default: 'toggle'
    },
    modelValue: {
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  emits: ['GdChange']
}
</script>

<style scoped>
/*Simple css to style it like a toggle switch*/
.slider {
  border: 1px solid transparent;
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  box-sizing: border-box;
  outline: none;
}

.slider:hover,
input:focus+.slider {
  border: 1px solid var(--gd-color-primary);
}

.slider:before {
  background-color: var(--color-background);
  bottom: 2px;
  content: '';
  left: 2px;
  position: absolute;
  transition: 0.4s;

  @apply h-3 w-3 bottom-[1px] left-[1px] sm:h-4 sm:w-4 sm:bottom-[2px] sm:left-[2px];
}

input:checked+.slider {
  background-color: var(--gd-color-second);
}

input:checked+.slider:hover,
.theme-switch input:checked:focus+.slider {
  border: 1px solid var(--gd-color-focus);
}

input:checked+.slider:before {
  @apply translate-x-[14px] sm:translate-x-[18px];
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
