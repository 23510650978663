<template>
  <div class="dark-mode-toggle px-1 md:px-2">
    <gd-toggle
      :modelValue="isDarkMode"
      :title="'Chế độ: ' + isDarkMode ? 'Tối' : 'Sáng'"
      v-on:gd-change="enableDarkMode"
    >
    </gd-toggle>
  </div>
</template>

<script setup>
import GdToggle from '@/components/base/button/GdToggle.vue'

import { ref } from 'vue'

const isDarkMode = ref(false)

/**
 * @description
 * Set background color cho theme theo chế độ hiện tại
 * @author Đông Thẩm
 * @date 27-09-2023
 */
const setThemeColor = () => {
  if (localStorage.theme === 'dark') {
    document.documentElement.classList.add('dark')
    localStorage.setItem('theme', 'dark')
    isDarkMode.value = true
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.setItem('theme', 'light')
  }
}

setThemeColor()

/**
 * @description
 * Bật/Tắt darkmode
 * @author Đông Thẩm
 * @date 27-09-2023
 */
const enableDarkMode = () => {
  isDarkMode.value = !isDarkMode.value
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
    localStorage.setItem('theme', 'dark')
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.setItem('theme', 'light')
  }
}
</script>

<style>
.dark-mode-toggle .slider:before {
  background-image: url(@/assets/svgs/sun.svg);
  background-repeat: no-repeat;
  background-position: center;

  @apply bg-[length:10px] md:bg-[length:12px];
}

.dark-mode-toggle input:checked + .slider:before {
  background-image: url(@/assets/svgs/moon.svg);
  background-repeat: no-repeat;
  background-position: center;

  @apply bg-black bg-[length:10px] md:bg-[length:12px];
}
</style>
