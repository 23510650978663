import { createPinia } from 'pinia'
// import { markRaw } from 'vue'
// import router from '@/router/index'
// import { type Router } from 'vue-router'

const piniaInstance = createPinia()

// declare module 'pinia' {
//   export interface PiniaCustomProperties {
//     router: Router
//   }
// }

// piniaInstance.use(({ store }) => {
//   store.router = markRaw(router)
// })

export default piniaInstance
