<script lang="ts" setup>
import { useAttrs } from 'vue'
import { Label } from 'radix-vue'
import { useFormField } from './useFormField'
import { cn } from '@/lib/utils'

defineOptions({
  inheritAttrs: false
})

withDefaults(
  defineProps<{
    isRequired?: boolean
  }>(),
  { isRequired: false }
)

const { error, formItemId } = useFormField()
const { class: className, ...rest } = useAttrs()
</script>

<template>
  <Label
    :class="
      cn(
        'block text-sm tracking-tight font-medium text-foreground text-left',
        className ?? '',
        error && 'text-red-500'
      )
    "
    :for="formItemId"
    v-bind="rest"
  >
    <slot />
    <span class="text-red-500" v-if="isRequired" title="Bắt buộc"> * </span>
  </Label>
</template>
