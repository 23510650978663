<script setup lang="tsx">
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button'
import { type Component } from 'vue'
import { storeToRefs } from 'pinia'
import { sidebarStore } from '@/stores/sidebarStore'

type GenericObject = { [key: string]: any }

interface Props {
  as?: Component
  item: GenericObject
  childMenu?: Array<any>
  class?: string
}

withDefaults(defineProps<Props>(), {
  as: Collapsible,
  childMenu: () => [],
  class: ''
})

const { getActivatedParent: activatedParent } = storeToRefs(sidebarStore)
const { getActivatedPageId: activatedPageId } = storeToRefs(sidebarStore)
</script>

<template>
  <component
    :is="as"
    :open="activatedParent.some((x) => x == item.PageMenuId)"
    :key="item.PageMenuId"
  >
    <Button
      as-child
      v-on:click="
        sidebarStore.updatedActivatedParent(item.PageMenuId, item.IsParent, item.PageMenuId)
      "
      variant="custom"
      class="w-full px-4 justify-between rounded-none hover:opacity-90 border-l-4 sidebarMenuBtn"
      :class="[
        activatedPageId == item.PageMenuId
          ? 'border-l-[var(--gd-color-primary)] bg-secondary'
          : 'border-background'
      ]"
      :style="`padding-left: ${item.Level * 10}px !important`"
    >
      <div class="flex items-center space-x-1">
        <div
          v-html="item.Icon"
          class="svgSideBar"
          :class="
            activatedParent.some((x) => x == item.PageMenuId) || activatedPageId == item.PageMenuId
              ? 'activeSvg'
              : ''
          "
        ></div>
        <!-- <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
          :class="[
            activatedParent.some((x) => x == item.PageMenuId) || activatedPageId == item.PageMenuId
              ? '!text-[#50B83C]'
              : '!text-primary'
          ]"
        >
          <g
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            v-html="item.Icon"
          ></g>
        </svg> -->
        <span
          :class="[
            'font-medium',
            activatedParent.some((x) => x == item.PageMenuId) || activatedPageId == item.PageMenuId
              ? 'text-[#50B83C]'
              : ''
          ]"
        >
          {{ item.Name }}
        </span>
      </div>
      <svg
        v-if="childMenu.length > 0"
        :class="[
          '!h-3 !w-3 transition-transform',
          activatedParent.some((x) => x == item.PageMenuId) || activatedPageId == item.PageMenuId
            ? 'rotate-90 text-[#50B83C]'
            : 'rotate-0'
        ]"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 8 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
        />
      </svg>
    </Button>
    
    <CollapsibleContent class="space-y-1 CollapsibleContent" v-if="childMenu.length > 0">
      <component :is="() => childMenu" class="!pl-2"></component>
    </CollapsibleContent>
    <slot />
  </component>
</template>

<style scoped>
.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.sidebarMenuBtn:hover span {
  color: #50b83c !important;
}

svg {
  @apply w-4 h-4;
}

.sidebarMenuBtn:hover:deep(.svgSideBar svg),
.activeSvg:deep(svg) {
  fill: #50b83c;
  color: #50b83c !important;
}
</style>
