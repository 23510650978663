<template>
  <div
    class="w-full h-full flex flex-col sm:flex-row items-center justify-evenly px-5 pb-10 text-gray-700 overflow-y-auto overflow-x-hidden">
    <div class="w-full mx-auto grid grid-cols-1 justify-items-center lg:w-1/2">
      <div class="text-7xl text-green-500 font-dark font-extrabold mb-8">403</div>
      <p class="text-xl md:text-3xl leading-normal mb-8 text-center">
        Xin lỗi, bạn không có quyền truy cập tài nguyên này.<br />
        Vui lòng sử dụng PC/Laptop để truy cập!
      </p>
    </div>
    <div class="w-full mx-auto lg:flex lg:justify-end lg:w-1/2">
      <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg"
        class="" alt="Page not available" />
    </div>
  </div>
</template>

<style scoped></style>
