import { defineStore, storeToRefs } from 'pinia'
import { ref, reactive, computed, type Ref } from 'vue'
import router from '@/router/index'
import authRequests from '@/js/utils/authRequests'
import piniaInstance from '@/stores/global'
import { sidebarStore } from './sidebarStore'
import type { RouteRecordRaw } from 'vue-router'
import { toast, type ToastTheme } from 'vue3-toastify'

export interface Permission {
  ActionPermsId: string
  PageMenuId: string
  Name: string
  Icon: string
  OrderBy: number
  ControllerName: string
  Path: string
  Description: string
  IsParent: boolean
  IsActive: boolean
  ParentPageId: string
  Level: number
}

const generateRoute = (
  allPermissions: Array<Permission>,
  childPermissions: Array<Permission>,
  permUsed: Array<Permission>
) => {
  const routes: RouteRecordRaw[] = []
  let removePerm: Array<Permission> = [...permUsed]
  childPermissions.map((el) => {
    if (!removePerm.some((i) => i.PageMenuId == el.ParentPageId)) {
      if (el.IsParent) {
        // '/:myParams*'
        const result = generateRoute(
          allPermissions,
          allPermissions.filter((item) => item.ParentPageId == el.PageMenuId),
          removePerm
        )

        const item: RouteRecordRaw = {
          path: el.ControllerName,
          name: el.PageMenuId,
          props: true,
          meta: {
            title: el.Name,
            desc: el.Description,
            requiresAuth: true
          },
          children: result.routes
        }

        routes.push(item)
        removePerm = [...result.removePerm]
        removePerm.push(el)
      } else {
        const item: RouteRecordRaw = {
          path: el.Path,
          name: el.PageMenuId,
          component: () => import(`@/views/admin/${el.ControllerName}/${el.Path}.vue`),
          props: true,
          meta: {
            title: el.Name,
            desc: el.Description
          }
        }

        routes.push(item)
      }
    }
  })

  return { routes, removePerm }
}

const createRoute = (Permissions: Array<Permission>) => {
  const { menus } = storeToRefs(sidebarStore)
  menus.value.length = 0
  menus.value.push(...Permissions)

  const route: any = generateRoute(
    Permissions,
    Permissions.filter((item) => item.ParentPageId == '00000000-0000-0000-0000-000000000000'),
    []
  )

  return route.routes
}

// const createRoute = (Permissions: Array<Permission>) => {
//   const routes: RouteRecordRaw[] = []
//   const { menus } = storeToRefs(sidebarStore)
//   menus.value.length = 0
//   menus.value.push(...Permissions)

//   Permissions.map((el) => {
//     if (el.ControllerName != null) {
//       const item: RouteRecordRaw = {
//         path: '/' + el.ControllerName + '/:myParams*',
//         name: el.ControllerName,
//         component: () => import(`@/views/admin/${el.Path}.vue`),
//         props: true,
//         meta: {
//           title: el.Name,
//           desc: el.Description
//         }
//       }

//       routes.push(item)
//     }
//   })

//   return routes
// }

export const authStore = defineStore('auth', () => {
  const user: Ref<object> = reactive(
    localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : {}
  )

  const returnUrl = ref('/admin/dashboard')

  const getUser = computed(() => user.value)
  const getReturnUrl = computed(() => returnUrl.value)

  const login = async (username: string, password: string) => {
    const response = await authRequests.login(username, password)

    if (response.data.Success) {
      // update pinia state
      const userData = Object.assign({}, response.data.Data)
      delete userData.Permissions
      user.value = Object.assign({}, userData)

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user.value))
      localStorage.setItem('TokenAuth', response.data.Data.Token)

      const Permissions = response.data.Data.Permissions

      const routes = createRoute(Permissions)

      routes.map((item: RouteRecordRaw) => router.addRoute('administrator', item))

      // redirect to previous url or default to home page
      router.push(router.currentRoute.value.query.redirect?.toString() || returnUrl.value)
    }
  }

  const validateToken = async () => {
    try {
      const { data } = await authRequests.validateToken()

      const userData = Object.assign({}, data)
      delete userData.Permissions
      user.value = Object.assign({}, userData)
      localStorage.setItem('user', JSON.stringify(user.value))
      localStorage.setItem('TokenAuth', data.Token)

      const Permissions: Array<Permission> = data.Permissions

      return { routes: createRoute(Permissions) }
    } catch (error: any) {
      let errorMsg = error?.message

      if (error?.response?.status == 401) {
        user.value = {}
        localStorage.removeItem('user')
        localStorage.removeItem('TokenAuth')
        errorMsg = error?.response?.errors
      }

      const theme = localStorage.getItem('theme')

      toast.error(errorMsg, {
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: true,
        closeOnClick: true,
        transition: 'flip',
        theme: (theme as ToastTheme) ?? 'auto'
      })

      return { routes: null }
    }
  }

  const logout = () => {
    try {
      user.value = {}
      localStorage.removeItem('user')
      localStorage.removeItem('TokenAuth')
    } finally {
      router.replace({
        name: 'login',
        query: { redirect: router.currentRoute.value.path }
      })
    }
  }

  return {
    user,
    returnUrl,
    getUser,
    getReturnUrl,
    login,
    validateToken,
    logout
  }
})(piniaInstance)
