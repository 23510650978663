<template>
  <a
    v-bind:class="['btn-link flex items-center cursor-pointer', { disabled: disable }]"
    :href="link"
  >
    <div v-if="iconClass" v-bind:class="['icon-btn', iconClass]"></div>
    <span class="align-middle text-sm font-medium md:text-base">{{ textInput }}</span>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonLink',
  components: {},
  props: {
    iconClass: {
      type: String,
      default: null
    },
    textClass: {
      type: String,
      default: null
    },
    textInput: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  setup() {},
  mounted() {}
}
</script>

<style scoped>
.btn-link {
  /* height: 36px; */
  width: auto;
  text-decoration: none;
  font-weight: var(--gd-text-weight-medium);
  color: var(--gd-color-primary);
  line-height: 12px;
  /* padding: 0 16px; */
}

.btn-link:hover {
  opacity: 0.8;
  /* background-color: var(--gd-color-focus); */
}

.btn-link:focus {
  color: var(--gd-color-pressed);
  /* background-color: var(--gd-color-focus); */
}

.btn-link.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: default;
}
</style>
