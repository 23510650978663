import './assets/main.css'
import './assets/index.css'

import { createApp } from 'vue'
import piniaInstance from '@/stores/global'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router'
import { LoadingPlugin } from 'vue-loading-overlay'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'

import App from './App.vue'

// import layout
import AdminLayout from '@/components/layout/admin/GDAdmin.vue'
import AuthLayout from '@/components/layout/auth/GDAuth.vue'

async function main() {
  const app = createApp(App)

  app.use(LoadingPlugin, {
    color: '#50B83C',
    loader: 'dots',
    backgroundColor: '#c3c3c3'
  })

  const options = {
    autoClose: 3000,
    hideProgressBar: false,
    pauseOnHover: true,
    closeOnClick: true,
    transition: 'flip',
    newestOnTop: true,
    theme: 'auto'
  } as ToastContainerOptions

  app.use(Vue3Toastify, options as ToastContainerOptions)

  const loading = app.config.globalProperties.$loading.show()

  piniaInstance.use(piniaPluginPersistedstate)
  app.use(piniaInstance)
  app.use(router)

  app.component('admin-layout', AdminLayout)
  app.component('auth-layout', AuthLayout)

  const timeout = new Promise<void>((_, reject) =>
    setTimeout(() => reject(new Error('Timeout')), 1500)
  )

  try {
    console.log('Router state before isReady:', router)
    await Promise.race([router.isReady(), timeout])
    app.mount('#app')

    setTimeout(() => {
      loading.hide()
    }, 600)

    console.log('Router is ready')
  } catch (error) {
    app.mount('#app')

    setTimeout(() => {
      loading.hide()
    }, 600)
    console.error('Error while waiting for router to be ready:', error)
  }

  console.log('Router initialization code executed')
}

main()
