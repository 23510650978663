function getEnvironment() {
  if (import.meta.env.PROD) {
    return 'production'
  }

  return 'development'
}

const API_URL = {
  development: import.meta.env.VITE_API_URL_DEV,
  production: import.meta.env.VITE_API_URL_PROD
}

const API_BASE_URL = {
  development: import.meta.env.VITE_API_BASE_URL_DEV,
  production: import.meta.env.VITE_API_BASE_URL_PROD
}

const GOOGLE_CONFIG = {
  development: {
    ClientId: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_DEV,
    ClientSecret: import.meta.env.VITE_APP_GOOGLE_CLIENT_SECRET_DEV,
    RedirectUrl: import.meta.env.VITE_APP_GOOGLE_REDIRECT_URL_DEV
  },
  production: {
    ClientId: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_PROD,
    ClientSecret: import.meta.env.VITE_APP_GOOGLE_CLIENT_SECRET_PROD,
    RedirectUrl: import.meta.env.VITE_APP_GOOGLE_REDIRECT_URL_PROD
  }
}

export const apiUrl = API_URL[getEnvironment()]

export const apiBaseUrl = API_BASE_URL[getEnvironment()]

export const googleConfig = GOOGLE_CONFIG[getEnvironment()]
