import { baseRequests } from './baseRequests'

class menuRequests {
  async getAll() {
    return await baseRequests().get('Menus')
  }

  async getMenuByRole(departmentId, jobPositionId) {
    return await baseRequests().get(
      `Menus/GetByRole?departmentId=${departmentId}&jobPositionId=${jobPositionId}`
    )
  }
}

export default new menuRequests()
