<template>
  <div class="h-full flex flex-col bg-secondary">
    <div class="flex justify-end items-center h-[56px] bg-secondary">
      <dark-mode-toggle></dark-mode-toggle>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import DarkModeToggle from '@/components/other/DarkModeToggle.vue'

export default {
  components: { DarkModeToggle }
}
</script>

<style scoped></style>
