<template>
  <div class="flex flex-col justify-center items-center gap-4">
    <div class="md:mb-2">
      <img src="@/assets/img/logo/logo.png" alt="" class="" />
    </div>
    <div class="p-4 rounded-lg border-solid border-2">
      <form @submit="handleLogin" class="inline-grid gap-2 md:gap-4 w-56 sm:w-64 md:w-80">
        <FormField v-slot="{ componentField }" name="Username" label="Tên đăng nhập">
          <FormItem>
            <FormLabel :isRequired="true">Tên đăng nhập</FormLabel>
            <FormControl>
              <Input
                type="text"
                placeholder=""
                v-bind="componentField"
                autofocus
                :error="form.errors.value.Username"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="Password" label="Mật khẩu">
          <FormItem>
            <FormLabel :isRequired="true">Mật khẩu</FormLabel>
            <FormControl>
              <div class="relative flex">
                <Input
                  :type="isShowPassword ? 'text' : 'password'"
                  v-bind="componentField"
                  :error="form.errors.value.Password"
                  :name="`password_${Math.random()}`"
                  autocomplete="new-password"
                />

                <button
                  v-on:click="showHidePassword"
                  type="button"
                  class="absolute right-0 w-9 h-9 p-2.5"
                >
                  <img
                    class="h-4 w-4"
                    :src="
                      isShowPassword
                        ? '/svg/eye-show.svg'
                        : '/svg/eye-hide.svg'
                    "
                    alt=""
                  />
                </button>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <div class="flex justify-between md:text-lg">
          <FormField v-slot="{ value, handleChange }" name="isRemember">
            <FormItem class="flex flex-row items-center space-y-0">
              <FormControl>
                <Checkbox :checked="value" @update:checked="handleChange" />
              </FormControl>
              <div class="leading-none ml-2">
                <FormLabel class="md:text-base">Nhớ mật khẩu</FormLabel>
              </div>
            </FormItem>
          </FormField>

          <button-link
            textInput="Quên mật khẩu?"
            link="/admin/forgot-password"
            target="_blank"
          ></button-link>
        </div>
        <Button type="submit" class="bg-primary text-primary-foreground"> Đăng nhập </Button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject } from 'vue'
import { useForm } from 'vee-validate'
import { Button } from '@/components/ui/button'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import ButtonLink from '@/components/base/button/ButtonLink.vue'

import { authStore } from '@/stores/authStore'
import { handleRequestErrors } from '@/js/functions/handleRequestErrors'

const $loading: { [key: string]: any } | undefined = inject('$loading')

const schema = {
  Username: 'required|min:4|max:50',
  Password: 'required|min:6|max:20'
}

const isShowPassword = ref(false)

const form = useForm({
  validationSchema: schema
})

const handleLogin = form.handleSubmit(async (values) => {
  const loader = $loading?.show()

  try {
    await authStore.login(values.Username, values.Password)
  } catch (error: any) {
    const errors = handleRequestErrors(error)

    if (errors) {
      form.setErrors(errors.errors)
    }
  } finally {
    loader.hide()
  }
})

const showHidePassword = () => {
  isShowPassword.value = !isShowPassword.value
}
</script>

<style scoped></style>
