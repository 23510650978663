<template>
  <button
    type="button"
    class="flex items-center justify-center h-8 w-8 rounded md:w-9 md:h-9"
    v-on:click="$emit('GdClick')"
    :disabled="disable" :title="title"
  >
    <div class="text-primary">
      <slot name="desc-icon"></slot>
    </div>
    <span v-if="textInput" v-bind:class="textClass">{{ textInput }}</span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonIcon',
  components: {},
  props: {
    textClass: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    textInput: {
      type: String,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    }
  },

  emits: ['GdClick'],
  setup() {},
  mounted() {}
}
</script>

<style scoped>
.svg {
  @apply h-4 w-4 text-primary;
}

button:hover, button:focus {
  @apply bg-secondary;
}
</style>
