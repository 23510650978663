import { toast } from 'vue3-toastify'
import { localize } from '@vee-validate/i18n'
import { defineRule, configure } from 'vee-validate'
import { required, min, max, email } from '@vee-validate/rules'
import { authStore } from '@/stores/authStore'

defineRule('required', required)
defineRule('min', min)
defineRule('max', max)
defineRule('email', email)

configure({
  // create and set a localization handler
  generateMessage: localize('vi', {
    messages: {
      required: '{field} không được để trống!',
      min: '{field} phải có ít nhất 0:{length} ký tự!',
      max: `{field} chỉ được chứa tối đa 0:{length} ký tự!`,
      email: 'Email không đúng định dạng!'
    }
  })
})

export const handleRequestErrors = (error: any) => {
  if (error.response) {
    const errors = error.response.data

    if (errors.status == 400) {
      toast.error(errors.title)
      return errors
    } else if (errors.status == 401) {
      toast.error(errors?.errors)
      authStore.logout()
    } else {
      toast.error(errors.errors)
    }
  } else {
    toast.error(error?.message)
  }
}
