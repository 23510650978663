import axios from 'axios'
import { apiUrl } from '@/config/environment'

export const baseRequests = () => {
  const TokenAuth = localStorage.getItem('TokenAuth')
  let headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }

  if (TokenAuth != null) {
    headers.Authorization = `Bearer ${TokenAuth}`
  }

  return axios.create({
    baseURL: apiUrl,
    headers: headers
  })
}
