import { ref, computed, type Ref } from 'vue'
import { defineStore } from 'pinia'
import piniaInstance from './global'
import { type Permission } from './authStore'
import router from '@/router'
import menuRequests from '@/js/utils/menuRequests'

export const sidebarStore = defineStore('sidebar', () => {
  const activatedPageId: Ref<any[]> = ref([])
  const activatedParent: Ref<any[]> = ref([])
  const menus: Ref<Permission[]> = ref([])
  const isShowSidebar: Ref<Boolean> = ref(localStorage.getItem('isShowSidebar') == 'true')

  const getActivatedPageId = computed(() => activatedPageId.value)
  const getActivatedParent = computed(() => activatedParent.value)
  const getMenus = computed(() => menus.value)
  const getIsShowSidebar = computed(() => isShowSidebar)

  const getMenuByRole = async (departmentId: string, jobPositionId: string) => {
    const resultMenu = await menuRequests.getMenuByRole(departmentId, jobPositionId)
    menus.value = resultMenu.data

    return resultMenu.data
  }

  async function updatedActivatedParent(id: string, isParent: boolean, nameRoute: string) {
    if (activatedParent.value.some((x) => x == id)) {
      activatedParent.value.splice(activatedParent.value.indexOf(id), 1)
    } else if (isParent) {
      activatedParent.value.push(id)
    } else if (!getActivatedPageId.value.some((x) => x == id)) {
      activatedPageId.value.length = 0
      activatedPageId.value.push(id)
      // if (childPage != '') {
      //   await router.push({ name: childPage, params: { myParams: childPage } })
      // } else {
      await router.push({ name: nameRoute })
      // }
    }

    if (!isParent && window.innerWidth < 1024) {
      isShowSidebar.value = false
      localStorage.setItem('isShowSidebar', String(false))
    }
  }

  function updatedActiveMenu(name: any) {
    if (name != 'dashboard') {
      const currentPage = menus.value.find((x) => x.PageMenuId === name && name != null)

      if (currentPage != null) {
        activatedPageId.value.length = 0
        activatedPageId.value.push(currentPage.PageMenuId)
        if (!activatedParent.value.some((el) => el == currentPage.ParentPageId)) {
          const newArr = [...new Set(recursiveActiveMenu(currentPage.ParentPageId))]
          activatedParent.value.push(...newArr)
        }
      }
    } else {
      activatedPageId.value.length = 0
      activatedPageId.value.push(0)
    }
  }

  const recursiveActiveMenu: any = (idChild: string) => {
    const ids = [idChild]
    let idsParent = []
    const parent = menus.value.find((el) => el.PageMenuId == idChild)

    if (parent != null && parent?.ParentPageId != '00000000-0000-0000-0000-000000000000') {
      idsParent = structuredClone(recursiveActiveMenu(parent.ParentPageId))
    }

    return [...ids, ...idsParent]
  }

  const changeVisibleSidebar = () => {
    isShowSidebar.value = !isShowSidebar.value
    localStorage.setItem('isShowSidebar', String(isShowSidebar.value))
  }

  return {
    activatedPageId,
    activatedParent,
    menus,
    isShowSidebar,
    getActivatedPageId,
    getActivatedParent,
    getMenus,
    getIsShowSidebar,
    updatedActiveMenu,
    updatedActivatedParent,
    getMenuByRole,
    changeVisibleSidebar
  }
})(piniaInstance)

// export const sidebarStore = useSidebarStore(piniaInstance)
