<template>
  <div class="md:flex justify-end w-full h-screen">
    <admin-sidebar ref="adminSidebar"></admin-sidebar>

    <div :class="[
      'h-screen w-full !transition-all !duration-700',
      isShowSidebar ? 'md:w-[calc(100%_-_250px)]' : 'md:w-full'
    ]">
      <admin-header></admin-header>
      <slot></slot>
      <admin-footer></admin-footer>
    </div>
  </div>
</template>

<script lang="ts">
import AdminHeader from './AdminHeader.vue'
import AdminFooter from './AdminFooter.vue'
import AdminSidebar from './AdminSidebar.vue'

import { ref } from 'vue'
import { sidebarStore } from '@/stores/sidebarStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'GdAdmin',
  components: {
    'admin-header': AdminHeader,
    'admin-footer': AdminFooter,
    'admin-sidebar': AdminSidebar
  },
  data() {
    return {
      isShowSidebar: storeToRefs(sidebarStore).getIsShowSidebar.value,
      adminSidebar: ref(null)
    }
  },
  // mounted() {
  //   this.isShowSidebar = localStorage.getItem('isShowSidebar') == 'true'
  // }
}
</script>

<style scoped></style>
